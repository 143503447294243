import React from 'react';
import _ from 'lodash';
import Masonry from 'react-masonry-component';
import ModalImage from "react-modal-image";
import {classNames, withPrefix, markdownify} from '../utils';
import SectionActions from './SectionActions';

const masonryOptions = {
    transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }

export default class GallerySection extends React.Component {
    render() {
        let section = _.get(this.props, 'section', null);
        const childElements = _.get(section, 'media', null).map(function(element){
           return (
                <li className="image-element-class">
                  <ModalImage
                    small={element}
                    large={element}
                    />
                </li>
            );
        });

        return (
            <section className="section section--gallery">
              {_.get(section, 'title', null) && (
              <div className="container container--md align-center">
                <h2 className="section__title">{_.get(section, 'title', null)}</h2>
              </div>
              )}
              <Masonry
                  className={'my-gallery-class'} // default ''
                  elementType={'ul'} // default 'div'
                  options={masonryOptions} // default {}
                  disableImagesLoaded={false} // default false
                  updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                  imagesLoadedOptions={imagesLoadedOptions} // default {}
              >
                  {childElements}
              </Masonry>
            </section>
        );
    }
}
