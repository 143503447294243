import React from 'react';
import _ from 'lodash';
import Slide from 'react-reveal/Slide';

import {classNames, withPrefix, markdownify} from '../utils';
import Product from './Product';

export default class ProductsSection extends React.Component {
    render() {
        let section = _.get(this.props, 'section', null);
        console.log(section,"products");
        return (
            <section className="section section--products">
              {_.map(_.get(section, 'products', null), (product, product_idx) => (
                <Product product={product}></Product>
              ))}
            </section>
        );
    }
}
