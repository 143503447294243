import React from 'react';
import _ from 'lodash';
import Slide from 'react-reveal/Slide';

import {classNames, withPrefix, markdownify} from '../utils';

export default class Product extends React.Component {
    render() {
        let product = _.get(this.props, 'product', null);
        console.log(product)
        return (
            <div className="product">
              {_.get(product, 'name', null) && (
              <div className="container container--md align-center">
                <h2 className="section__title">{_.get(product, 'name', null)}</h2>
              </div>
              )}
              {_.get(product, 'id', null) && (
              <div className="container container--lg">
                <div key={product.id} className={classNames('flex', 'flex--middle', 'flex--center', 'flex--col-2')}>
                  <div className={classNames('section__body', 'cell')}>
                    <Slide up duration={2500}>
                      {_.get(product, 'image', null) && (
                        <img className="feature__image" src={ withPrefix(_.get(product, 'image', null)) } />
                      )}
                    </Slide>
                    {_.get(product, 'description', null) && (
                    <div className="section__copy">
                      {markdownify(_.get(product, 'description', null))}
                    </div>
                    )}
                    <h3 className="section__blocktitle"><sup>$</sup>{_.get(product, 'price', null)}</h3>
                    <button className="snipcart-add-item btn btn--secondary"
                      data-item-id={product.id}
                      data-item-price={product.price}
                      data-item-url={product.path}
                      data-item-description={product.description}
                      data-item-image={product.image}
                      data-item-name={product.name}
                      data-item-custom1-name="Includes Shipping"
                      data-item-custom1-type="readonly"
                      data-item-custom1-value="Item(s) will ship via USPS please allow 10 days for delivery.">
                      {product.cartText}
                    </button>
                  </div>
                </div>
              </div>
            )}
            </div>
        );
    }
}
